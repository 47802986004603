import {Dialog} from "@headlessui/react"
import clsx from "clsx"
import {cloneElement, createContext, useContext, useMemo, useState} from "react"

import {IconClose} from "~/components/elements/Icon"

type DrawerContext = {
  activeId: string | null
  open: (id: string) => void
  close: () => void
}

const DrawerContext = createContext<DrawerContext | null>(null)

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerProvider")
  }
  return context
}

type DrawerProviderProps = {children: React.ReactNode}

const DrawerProvider = ({children}: DrawerProviderProps) => {
  const [activeId, setActiveId] = useState<string | null>(null)

  const handleOpen = (id: string) => {
    setActiveId(id)
  }

  const handleClose = () => {
    setActiveId(null)
  }

  return (
    <DrawerContext.Provider
      value={{activeId, open: handleOpen, close: handleClose}}
    >
      {children}
    </DrawerContext.Provider>
  )
}

type DrawerProps = {
  id: string
  side?: "left" | "right"
  initialFocus?: React.MutableRefObject<HTMLElement | null>
  activator?: React.ReactElement
  children: React.ReactNode
}

export const Drawer = ({
  id,
  side = "left",
  initialFocus,
  activator,
  children,
}: DrawerProps) => {
  const {activeId, open, close} = useDrawer()

  const activatorMarkup = useMemo(
    () =>
      activator
        ? cloneElement(activator, {
            onClick: () => {
              open(id)
            },
          })
        : null,
    [activator, id, open],
  )

  return (
    <>
      {activatorMarkup}
      <Dialog
        open={id === activeId}
        onClose={close}
        className="relative z-50"
        onPointerDown={(e: React.SyntheticEvent) => e.stopPropagation()}
        initialFocus={initialFocus}
      >
        <div className="fixed inset-0 bg-spacemist/80 overflow-y-auto" />

        <div
          className={clsx(
            "fixed inset-0 flex items-start",
            side === "left" ? "justify-start" : "justify-end",
          )}
        >
          <Dialog.Panel className="relative flex flex-col w-3/4 max-w-[375px] h-screen p-[20px] max-md:pb-5 bg-white">
            <button onClick={close}>
              <IconClose
                stroke="cosmosgrey"
                className="cursor-pointer absolute top-[20px] right-[20px]"
              />
            </button>
            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}

Drawer.Provider = DrawerProvider
