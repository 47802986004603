import type {OptimisticCartLine} from "@shopify/hydrogen"
import {Image, Money} from "@shopify/hydrogen"

import {UpdateCartInput} from "~/components/products/UpdateCartInput"
import type {CartLine} from "~/types/cart"

type CartDrawerLineItemCardProps = {
  lineItem: OptimisticCartLine<CartLine>
}

export const CartDrawerLineItemCard = ({
  lineItem,
}: CartDrawerLineItemCardProps) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex items-start gap-1">
        <div className="min-w-[50px] min-h-[50px]">
          {!!lineItem.merchandise.image && (
            <Image
              data={lineItem.merchandise.image}
              width={50}
              height={50}
              aspectRatio="1/1"
              loading="lazy"
            />
          )}
        </div>
        <div className="font-bold text-cosmosgrey">
          {lineItem.merchandise.product.title}
        </div>
      </div>

      <div className="flex self-end items-center gap-1 md:gap-2">
        <UpdateCartInput lineItem={lineItem} showDeleteOption />
        <div className="min-w-[75px]">
          {lineItem.cost?.totalAmount && (
            <Money data={lineItem.cost.totalAmount} />
          )}
        </div>
      </div>
    </div>
  )
}
