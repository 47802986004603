import {Await, useFetchers} from "@remix-run/react"
import {CartForm, Money, useOptimisticCart} from "@shopify/hydrogen"
import {Suspense, useEffect, useState} from "react"
import type {CartApiQueryFragment} from "storefrontapi.generated"

import {CartDrawerLineItemCard} from "~/components/cards/CartDrawerLineItemCard"
import {Button} from "~/components/elements/Button"
import {IconCircleCheck} from "~/components/elements/Icon"
import {Drawer} from "~/components/global/Drawer"
import {flattenExactConnection} from "~/utilities/flatten-exact-connection"

export const CART_DRAWER_ID = "cart-drawer"

type CartDrawerProps = {
  cart: Promise<CartApiQueryFragment | null>
}

export const CartDrawer = ({cart}: CartDrawerProps) => {
  return (
    <Drawer id={CART_DRAWER_ID} side="right">
      <Suspense>
        <Await resolve={cart}>
          {(cart) => <CartDrawerContent cart={cart} />}
        </Await>
      </Suspense>
    </Drawer>
  )
}

type CartDrawerContentProps = {
  cart: CartApiQueryFragment | null
}

const CartDrawerContent = ({cart: originalCart}: CartDrawerContentProps) => {
  const cart = useOptimisticCart<CartApiQueryFragment | null>(originalCart)
  const hasItemsOptimistic = !!cart?.lines.nodes.length

  const [title, setTitle] = useState("")

  const fetchers = useFetchers()

  useEffect(() => {
    const fetcher = fetchers.find((_) => _.state === "submitting")

    const {action} = JSON.parse(
      fetcher?.formData?.get("cartFormInput")?.toString() ?? "{}",
    )

    switch (action) {
      case CartForm.ACTIONS.LinesAdd: {
        setTitle("Item successfully added")
        break
      }
      case CartForm.ACTIONS.LinesUpdate: {
        setTitle("Item successfully updated")
        break
      }
      case CartForm.ACTIONS.LinesRemove: {
        setTitle("Item successfully removed")
        break
      }
    }
  }, [fetchers])

  return (
    <>
      {title ? (
        <div className="flex gap-1 mb-3">
          <IconCircleCheck />
          <h3 className="text-subheading-2 font-semibold">{title}</h3>
        </div>
      ) : (
        <h3 className="mb-3 text-subheading-2 font-semibold">
          Your cart {!!cart?.totalQuantity && <>({cart.totalQuantity})</>}
        </h3>
      )}
      {hasItemsOptimistic ? (
        <>
          <div className="flex flex-col gap-3 overflow-y-auto mb-3">
            {flattenExactConnection(cart?.lines).map((lineItem) => (
              <CartDrawerLineItemCard key={lineItem.id} lineItem={lineItem} />
            ))}
          </div>
          <div className="flex mt-auto justify-between text-subheading-2 font-bold text-cosmosgrey">
            <div>Total</div>
            {!!cart?.cost?.subtotalAmount?.amount && (
              <Money data={cart.cost.subtotalAmount} />
            )}
          </div>
          <div className="mt-2">
            <Button
              to={cart?.checkoutUrl}
              size="lg"
              as="link"
              width="full"
              variant="tertiary"
              disabled={cart?.isOptimistic}
            >
              Checkout
            </Button>
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-2 items-center py-5">
          <p className="font-semibold">Your cart is empty</p>
        </div>
      )}
    </>
  )
}
